import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function SubmitReviewConfirmation(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Submit Review</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to submit this review?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={props.handleClose}>Cancel</Button>
                <Button variant='contained' onClick={props.handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubmitReviewConfirmation;