import React from 'react';
import { Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

function StaffReviewCardHeader(props) {
    return (
        <Stack direction="row" justifyContent='space-between'>
            <Stack spacing={1}>
                <Typography variant='normal' fontSize='14px'>
                    <strong>Physician: </strong> Dr. {props.review.card.doctor.first_name} {props.review.card.doctor.last_name}
                </Typography>
                <Typography variant='normal' fontSize='14px'>
                    <strong>Card: </strong> {props.review.card.name} ({props.review.card.external_card_id})
                </Typography>
                <Typography variant='normal' fontSize='14px'>
                    <strong>Managed by: </strong> {props.review.card.reviewers.map(reviewer => reviewer.first_name + ' ' + reviewer.last_name).join(', ')}
                </Typography>
            </Stack>
            <Stack direction='row' spacing={4} alignItems='end'>
                <ToggleButtonGroup
                    color='primary'
                    value={props.activeView}
                    exclusive
                    onChange={(event, value) => {
                        if (value !== null) { props.setActiveView(value); }
                    }}
                >
                    <ToggleButton value='changesToReview'>
                        Changes to Review
                    </ToggleButton>
                    <ToggleButton value='allCardItems'>
                        All Card Items
                    </ToggleButton>
                </ToggleButtonGroup>
                { props.canSubmit &&
                    <Button variant='blueContained' onClick={() => props.setIsShowingSubmitConfirmation(true)}>
                        Submit
                    </Button>
                }
            </Stack>
        </Stack>
    );
}

export default StaffReviewCardHeader;
