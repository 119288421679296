import { WarningAmberRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { campaignSlice, useUpdateCampaignMutation } from '../../api/campaignSlice';

function CampaignStatusTracker(props) {
    const activeStep = useMemo(() => {
        switch (props.campaign?.status) {
        case 'open':
            return 0;
        case 'pending':
            return 1;
        case 'active':
            return 2;
        case 'staff_review':
            return 3;
        case 'processing':
            return 4;
        case 'complete':
            return 5;
        default:
            return 0;
        }
    }, [props.campaign]);

    const descriptions = [
        'Configure your campaign for the users and cards that you want. Use the \'Generate Preview\' button to see a summary of the savings your campaign will generate.',
        'Hang tight! We are currently generating the dashboards for your campaign based on your preferences. Check back soon for updates.',
        'Your campaign is now live and physician dashboard links can be put in the hands of surgeons. Monitor this page for live updates of your generated savings.',
        'Physician access to dashboards is now closed and staff are making final reviews of the changes made.',
        'We\'re summarizing all the changes from this campaign and building reports to help you easily view the impact. Check back soon for updates.',
        'Your campaign is complete! Great work!'
    ];

    return (
        <Paper elevation={2}>
            <Stack p={3} spacing={2}>
                <Stack direction='row' justifyContent='space-between' textAlign='end'>
                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>Campaign Status</Typography>
                    <Typography sx={{ width: '40%', fontSize: '14px' }}>{descriptions[activeStep]}</Typography>
                </Stack>
                <Stepper activeStep={activeStep}>
                    <Step key={0}>
                        <StepLabel>Campaign Setup</StepLabel>
                    </Step>
                    <Step key={1}>
                        <StepLabel>Dashboard Generation</StepLabel>
                        <Typography></Typography>
                    </Step>
                    <Step key={2}>
                        <StepLabel>Physician Engagement</StepLabel>
                    </Step>
                    <Step key={3}>
                        <StepLabel>Staff Review</StepLabel>
                    </Step>
                    <Step key={4}>
                        <StepLabel>Report Generation</StepLabel>
                    </Step>
                    <Step key={5}>
                        <StepLabel>Campaign Results</StepLabel>
                    </Step>
                </Stepper>
                <CampaignStatusActions 
                    activeStep={activeStep} 
                    campaign={props.campaign}
                    campaignPreview={props.campaignPreview}
                    setCampaignPreview={props.setCampaignPreview}
                    settings={props.settings}
                    isShowingSettings={props.isShowingSettings}
                    isManagingPhysicians={props.isManagingPhysicians}
                    isManagingStaffReview={props.isManagingStaffReview}
                    setIsShowingSettings={props.setIsShowingSettings} 
                    setIsManagingPhysicians={props.setIsManagingPhysicians}
                    setIsManagingStaffReview={props.setIsManagingStaffReview}
                    shouldShowMissingPreviewError={props.shouldShowMissingPreviewError}
                    shouldShowGetStartedMessage={props.shouldShowGetStartedMessage}
                />
            </Stack>
        </Paper>
    )
}

function CampaignStatusActions(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoadingReport, setIsLoadingReport] = useState(false);

    const [getPreview, { isLoading: previewIsLoading }] = campaignSlice.endpoints.getCampaignPreview.useLazyQuery();

    async function handleGeneratePreview() {
        getPreview({
            campaignId: props.campaign.id,
            specialties: props.settings.specialties.map((specialty) => specialty.name),
        }).unwrap().then((response) => {
            props.setCampaignPreview(response);
            props.setIsShowingSettings(false);
        });
    }

    const generateReport = async(reportType) => {
        setIsLoadingReport(true);
        return new Promise((res) => {
            axios({
                url: `/api/campaigns/${props.campaign.id}/report?report_type=${reportType}`,
                method: 'GET',
            }).then(() => {
                setIsLoadingReport(false);
                res();
            }).catch(() => {
                setIsLoadingReport(false);
                res();
            });
        });
    }

    function handleManagePhysiciansClick() {
        props.setIsManagingPhysicians(!props.isManagingPhysicians);
        if (props.isManagingStaffReview) {
            props.setIsManagingStaffReview(false);
        }
    }

    function handleManageStaffReviewClick() {
        props.setIsManagingStaffReview(!props.isManagingStaffReview);
        if (props.isManagingPhysicians) {
            props.setIsManagingPhysicians(false);
        }
    }

    return (
        <>
            <Stack justifyContent='space-between' alignItems='center' direction='row'>
                <Stack direction='row' spacing={2}>
                    { props.shouldShowMissingPreviewError && 
                        <Stack direction='row' spacing={1}>
                            <WarningAmberRounded color='warning' />
                            <Typography variant='normal' sx={{ fontSize: '16px' }}>The previous preview for this campaign is outdated. Please generate a new one.</Typography>
                        </Stack>
                    }
                    { props.shouldShowGetStartedMessage &&
                        <Stack direction='row' spacing={1} >
                            <WarningAmberRounded color='info' />
                            <Typography variant='normal' sx={{ fontSize: '16px' }}>{'No previews of this campaign have been made yet. Select \'Generate Preview\' to get started.'}</Typography>
                        </Stack>
                    }
                </Stack>
                <Stack direction='row' spacing={2}>
                    { props.activeStep === 0 &&
                        <>
                            <Button variant='outlined' disabled={previewIsLoading} onClick={() => props.setIsShowingSettings(!props.isShowingSettings)}>{props.isShowingSettings ? 'Show Preview' : 'Show Settings'}</Button>
                            <LoadingButton variant='outlined' loading={previewIsLoading} onClick={handleGeneratePreview}>Generate Preview</LoadingButton>
                            <Button variant='contained' onClick={() => setDialogOpen(true)}>Start Campaign</Button>
                        </>
                    }
                    { props.activeStep === 4 &&
                        <Button variant='outlined' disabled={previewIsLoading} onClick={() => props.setIsShowingSettings(!props.isShowingSettings)}>{props.isShowingSettings ? 'Show Summary' : 'Show Settings'}</Button>
                    }
                    { (props.activeStep === 2 || props.activeStep === 3) && 
                        <Button variant='outlined' onClick={handleManageStaffReviewClick}>{props.isManagingStaffReview ? 'Show Dashboard' : 'Manage Staff Review'}</Button>
                    }
                    { props.activeStep === 2 && 
                        <>
                            <Button variant='outlined' onClick={handleManagePhysiciansClick}>{props.isManagingPhysicians ? 'Show Dashboard' : 'Manage Physicians'}</Button>
                            <Button variant='contained' onClick={() => setDialogOpen(true)}>Start Staff Review</Button>
                        </>
                    }
                    { props.activeStep === 3 && 
                        <>
                            <Button variant='contained' onClick={() => setDialogOpen(true)}>End Campaign</Button>
                        </>
                    }
                    { (props.activeStep !== 0 && props.activeStep !== 1) && 
                        <>
                            <LoadingButton variant='contained' loading={isLoadingReport} onClick={() => generateReport('item_changes')}>Generate Item Changes Report</LoadingButton>
                            {props.activeStep !== 5 && 
                               <LoadingButton variant='contained' loading={isLoadingReport} onClick={() => generateReport('swaps')}>Generate Item Swaps Report</LoadingButton>
                            }
                        </>
                    }
                </Stack>
            </Stack>
            <ChangeCampaignStatusDialog open={dialogOpen} isLoading={isLoadingReport} setOpen={setDialogOpen} activeStep={props.activeStep} campaign={props.campaign} />
        </>
    )
}

function ChangeCampaignStatusDialog(props) {
    const [updateCampaign] = useUpdateCampaignMutation();

    function onClose() {
        props.setOpen(false);
    }

    const handleCampaignStart = async() => {
        await updateCampaign({
            campaignId: props.campaign.id,
            requestBody: {
                status: 'pending'
            }
        });
        onClose();
    }

    const beginStaffReview = async() => {
        await updateCampaign({
            campaignId: props.campaign.id,
            requestBody: {
                status: 'staff_review'
            }
        });
        onClose();
    }

    const handleCampaignEnd = async() => {
        await updateCampaign({
            campaignId: props.campaign.id,
            requestBody: {
                status: 'processing'
            }
        });
        onClose();
    }

    if (props.activeStep === 0) {
        return (
            <Dialog open={props.open} onClose={onClose}>
                <DialogTitle>Start Campaign</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to start this campaign? Once started, you will not be able to make any changes to the campaign settings.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleCampaignStart}>Start Campaign</Button>
                </DialogActions>
            </Dialog>
        )
    } else if (props.activeStep === 2) {
        return (
            <Dialog open={props.open} onClose={onClose}>
                <DialogTitle>Move to Staff Review</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to move to the staff review phase of this campaign? This will prevent all associated physicians from making any further changes to their dashboards.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={beginStaffReview}>Begin Staff Review</Button>
                </DialogActions>
            </Dialog>
        );
    } else if (props.activeStep === 3) {
        return (
            <Dialog open={props.open} onClose={onClose}>
                <DialogTitle>End Campaign</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to end this campaign? This will prevent all associated physicians from making any further changes to their dashboards.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleCampaignEnd}>End Campaign</Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return null;
    }
}

function GeneratePreviewDialog(props) {
    function onClose() {
        props.setOpen(false);
    }

    return (
        <Dialog open={props.open} onClose={onClose}>
            <DialogTitle>Generate Preview</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to re-generate a preview for this campaign? This will delete all previous edits made to this campaign and create a new 
                    campaign from scratch with the current settings.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton onClick={props.generatePreview} loading={props.isLoading}>Generate</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default CampaignStatusTracker;
