import { Collapse, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { theme } from '../../../theme';
import PhysicianCardArea from './PhysicianCardArea';
import OverdueChip from '../../CampaignMonitoring/OverdueChip';

function PhysicianEntry(props) {
    const isExpanded = props.selectedPhysician === props.physician.id;

    const numOverdue = useMemo(() => {
        return props.drafts.reduce((acc, draft) => {
            return acc + draft.num_overdue_reviews;
        }, 0);
    }, [props.drafts]);

    function handleClick() {
        if (isExpanded) {
            props.setSelectedPhysician(null);
        } else {
            props.setSelectedPhysician(props.physician.id);
        }
    }

    return (
        <Stack>
            <Stack 
                p={2} 
                onClick={handleClick} 
                direction='row' 
                alignItems='center' 
                justifyContent='space-between'
                style={{
                    cursor: 'pointer',
                }}
            >
                <Stack direction='row' alignItems='center' spacing={1}>
                    {isExpanded ? 
                        <ExpandLess style={{ color: theme.palette.subtext.main }} /> : 
                        <ExpandMore style={{ color: theme.palette.subtext.main }} />
                    }
                    <Typography variant='normal' fontSize='16px'>Dr. {props.physician.last_name}, {props.physician.first_name}</Typography>
                    { numOverdue > 0 &&
                        <OverdueChip numOverdue={numOverdue} format='staff_review_count' />
                    }
                </Stack>
                { props.status === 'active' &&
                    <Typography color={theme.palette.subtext.main} variant='normal' fontSize='16px'>{props.drafts.length} Card{props.drafts.length === 1 ? '' : 's'} Ready for Review</Typography>
                }
                { props.status === 'completed' && 
                    <Typography color={theme.palette.subtext.main} variant='normal' fontSize='16px'>{props.drafts.length} Card{props.drafts.length === 1 ? '' : 's'} Reviewed</Typography>
                }
            </Stack>
            <Collapse in={isExpanded}>
                <PhysicianCardArea drafts={props.drafts} status={props.status} />
            </Collapse>
        </Stack>
    );
}

export default PhysicianEntry;
