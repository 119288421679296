import { Stack, Typography, Tooltip, Skeleton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import React from 'react';
import { theme } from '../../../theme';


function StaffReviewStatBox(props) {
    return (
        <Stack spacing={1} p={2} backgroundColor='white' width='20%' sx={{
            borderRight: '1px solid',
            borderColor: theme.palette.border.main,
            ':first-child': { borderRadius: '8px 0 0 8px' },
            ':last-child': { borderRadius: '0 8px 8px 0', borderRight: 'none' },
        }}>
            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='normal' fontSize='12px'>{props.title}</Typography>
                { props.tooltip && 
                    <Tooltip title={props.tooltip}>
                        <InfoOutlined style={{ opacity: 0.5 }} fontSize='12px' />
                    </Tooltip>
                }
            </Stack>
            { !props.isLoaded && 
                <Skeleton variant='text' width='20px' height='20px' />
            }
            { props.isLoaded &&
                <Typography variant='bold' fontSize='16px'>{props.value}</Typography>
            }
        </Stack>
    )
}

export default StaffReviewStatBox;