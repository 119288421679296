import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import AdditionalSettings from './AdditionalSettings';
import ComplianceSettings from './ComplianceSettings';
import ItemCategories from './ItemCategories';
import OptimizationTypes from './OptimizationTypes';
import SaveSettingsTemplateDialog from './SaveSettingsTemplateDialog';
import Specialties from './Specialties';
import { useUpdateCampaignMutation } from '../../../api/campaignSlice';
import ExcludedPhrases from './ExcludedPhrases';

function CampaignSettings(props) {
    const [isShowingTemplateDialog, setIsShowingTemplateDialog] = useState(false);
    const [updateCampaign] = useUpdateCampaignMutation();
    const isCampaignComplete = props.campaign.status === 'complete';

    async function saveSettings() {
        await updateCampaign({
            campaignId: props.campaign.id,
            requestBody: {
                settings: props.settings
            }
        });
    }

    return (
        <Stack spacing={3}>
            <Stack direction='row' px={3} alignItems='center' justifyContent='space-between'>
                <Typography variant='bold' sx={{ fontSize: '20px' }}>Campaign Settings</Typography>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Button variant='contained' onClick={saveSettings}>Save Settings</Button>
                </Stack>
            </Stack>
            <Stack direction='row' justifyContent='space-between' flexWrap='wrap' rowGap={2}>
                <OptimizationTypes disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                <AdditionalSettings disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                <Specialties disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                <ExcludedPhrases disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                <ItemCategories disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
            </Stack>
            <Stack direction='row' spacing={2}>
                { props.settings.compliance && 
                    <ComplianceSettings disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                }
            </Stack>
            <SaveSettingsTemplateDialog open={isShowingTemplateDialog} setOpen={setIsShowingTemplateDialog} campaign={props.campaign} settings={props.settings} />
        </Stack>
    )
}

export default CampaignSettings;
