import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Paper, Stack, Typography, Chip, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';


function ExcludedPhrases(props) {
    const [excludedPhrases, setExcludedPhrases] = useState(props.settings.excluded_phrases ?? []);
    const [textFieldContent, setTextFieldContent] = useState('');

    const handleRemovePhrase = (phrase) => {
        setExcludedPhrases(excludedPhrases.filter(p => p !== phrase))
        props.setSettings({ ...props.settings, excluded_phrases: excludedPhrases.filter(p => p !== phrase) });
    };

    const handleAddPhrase = (phrase) => {
        setExcludedPhrases([...excludedPhrases, phrase]);
        props.setSettings({ ...props.settings, excluded_phrases: [...excludedPhrases, phrase] });
        setTextFieldContent('');
    };

    return (
        <Paper elevation={2} sx={{ width: '23%' }}>
            <Stack p={3} spacing={2} height='100%' justifyContent='space-between'>
                <Stack spacing={2}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant='light' sx={{ fontSize: '18px' }}>Excluded Phrases</Typography>
                        <Tooltip title='Set specific phrases which, if present in an item name,will exclude those items from having their recommendations present in this campaign.'>
                            <InfoOutlined style={{ opacity: 0.5 }} />
                        </Tooltip>
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={1} rowGap={0.5} flexWrap='wrap'>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>Phrases: </Typography>
                        { excludedPhrases.length === 0 && <Typography variant='normal' sx={{ fontSize: '16px' }}>None</Typography> }
                        { excludedPhrases.map((phrase, index) => (
                            <Chip key={index} label={phrase} onDelete={() => handleRemovePhrase(phrase)} />
                        ))}
                    </Stack>
                </Stack>
                <Stack spacing={2}>
                    <TextField
                        value={textFieldContent}
                        onChange={event => setTextFieldContent(event.target.value)}
                        placeholder='Enter a phrase'
                        sx={{ width: '100%' }}
                    />
                    <Button 
                        variant='contained'
                        onClick={() => handleAddPhrase(textFieldContent)}
                    >
                        Add Phrase
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    )
}


export default ExcludedPhrases;
