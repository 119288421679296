import { Add, Check } from '@mui/icons-material';
import { Autocomplete, Button, CircularProgress, Dialog, DialogTitle, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { apiSlice, useCreateUserAssignmentMutation } from '../../../../api/apiSlice';
import { useGetCardOwnersQuery } from '../../../../api/cardSlice';

function AddReviewerAssignmentDialog(props) {
    const [selectedPhysician, setSelectedPhysician] = useState(null);
    const [physicianCards, setPhysicianCards] = useState([]);
    const { data: physicians = [] } = useGetCardOwnersQuery(props.hospitalId);
    const [getPhysicianCards, { isFetching: isFetchingCards, isSuccess: hasLoadedCards }] = apiSlice.endpoints.getCardsByCardOwner.useLazyQuery();
    const [createUserAssignment] = useCreateUserAssignmentMutation();

    async function handleChange(value) {
        setSelectedPhysician(value);
        if (value) {
            await getPhysicianCards({
                hospitalId: props.hospitalId, 
                cardOwnerId: value.id
            }).unwrap().then((payload) => {
                setPhysicianCards(payload);
            });
        } else {
            setPhysicianCards([]);
        }
    }

    async function handleAddCard(card) {
        await createUserAssignment({
            card: card,
            reviewer: props.reviewer
        });
    }

    async function handleAddAll() {
        physicianCards.forEach(async(card) => {
            await createUserAssignment({
                card: card,
                reviewer: props.reviewer
            });
        });
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth='sm'>
            <DialogTitle>
                Add Card to Reviewer
            </DialogTitle>
            <Stack p={2} spacing={2}>
                <Autocomplete
                    options={physicians}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Physician" />}
                    onChange={(e, value) => handleChange(value)}
                    value={selectedPhysician}
                />
                { selectedPhysician && hasLoadedCards &&
                    <Stack spacing={2} pl={2} display='grid'>
                        <Button variant='contained' sx={{ justifySelf: 'end' }} onClick={handleAddAll}>Add All Cards</Button>
                        { physicianCards?.map((card) => (
                            <>
                                <CardEntry key={card.id} card={card} onAddCard={handleAddCard} assignedCards={props.assignedCards} />
                                <Divider />
                            </>
                        ))}
                    </Stack>
                }
                { isFetchingCards && 
                    <Stack spacing={2} justifyContent='center' alignItems='center'>
                        <Typography>Loading...</Typography>
                        <CircularProgress />
                    </Stack>
                }
            </Stack>
        </Dialog>
    )
}

function CardEntry(props) {
    const isAssigned = props.assignedCards.some((assignedCard) => assignedCard.id === props.card.id);

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography sx={{ maxWidth: '55%' }}>{props.card.name}</Typography>
            { isAssigned ?
                <Button variant='contained' disabled startIcon={<Check />}>Assigned to Reviewer</Button> : 
                <Button variant='contained' startIcon={<Add />} onClick={() => props.onAddCard(props.card)}>Add to Reviewer</Button>
            }
        </Stack>
    )
}

export default AddReviewerAssignmentDialog;
