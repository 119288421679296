import { IconButton, Typography, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Tooltip, Collapse, ToggleButtonGroup, ToggleButton, Divider} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useCreateDraftCheckoutLogMutation, useGetReviewQuery, useLogUserActionMutation, useUpdateDraftMutation, useUpdateReviewMutation } from '../../api/staffReviewSlice';
import { ExpandLessRounded, ExpandMoreRounded, FlagRounded } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import FlagReviewDialog from './FlagReviewDialog';
import { format, parseISO } from 'date-fns';
import ChangesOnlyView from './ChangesOnlyView';
import TableHeader from './TableHeader';
import ReviewRow from './ReviewRow';
import useStaffReviewUser from '../Hooks/useStaffReviewUser';
import { differenceInMinutes } from 'date-fns';
import CheckedOutCardDialog from './CheckedOutCardDialog';
import CheckoutTimeoutDialog from './CheckoutTimeoutDialog';

function StaffReview() {
    const { draftId } = useParams();
    const { data: review = {}, isSuccess: reviewLoaded } = useGetReviewQuery(draftId);
    const { staffReviewUser: user } = useStaffReviewUser();
    const [updateReview] = useUpdateReviewMutation();
    const [updateDraft] = useUpdateDraftMutation();
    const [createCheckoutLog] = useCreateDraftCheckoutLogMutation();
    const [logUserAction] = useLogUserActionMutation();
    const [isShowingSubmitConfirmation, setIsShowingSubmitConfirmation] = useState(false);
    const [isShowingFlagDialog, setIsShowingFlagDialog] = useState(false);
    const [isShowingCheckedOutDialog, setIsShowingCheckedOutDialog] = useState(false);
    const [isShowingTimeoutDialog, setIsShowingTimeoutDialog] = useState(false);
    const [timeOfLastUpdate, setTimeOfLastUpdate] = useState(new Date());
    const [view, setView] = useState('only_changes');
    const tooltipText = 'You can only submit a review if all changes have been accepted or rejected you have provided feedback for each rejection.';
    const [isReadOnly, setIsReadOnly] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (reviewLoaded) {
            if (review.status === 'applied') {
                setIsReadOnly(true);
                return;  // If the review is already applied, we don't need to check for checkout status since it will always be readonly. 
            }

            const checkoutStatus = review.checkout;
            if (!checkoutStatus) {
                checkoutReview();
                return;
            }

            const currentTime = new Date();
            const timeSinceLastAction = differenceInMinutes(currentTime, new Date(review.last_staff_review_action_time));
            const timeSinceCheckout = differenceInMinutes(currentTime, new Date(checkoutStatus.time_of_action));
             
            if (checkoutStatus.action === 'checked_out' && checkoutStatus.reviewer.id !== user.id && (timeSinceLastAction < 15 || timeSinceCheckout < 15)) {
                setIsShowingCheckedOutDialog(true);
                logAction('user_checkout_blocked', { draftId: draftId });
                setIsReadOnly(true);
            } else if (checkoutStatus.action === 'checked_in') {
                checkoutReview();
            } else if (checkoutStatus.action === 'checked_out' && timeSinceLastAction >= 15 && timeSinceCheckout >= 15) {
                checkoutReview();
            }
        }
    }, [review, reviewLoaded]);

    const canSubmit = useMemo(() => {
        if (isReadOnly) {
            return false;
        }

        return Object.keys(review.items ?? {}).every((key) => {
            if (!review.recommendations[key]) {
                return true;
            }
            return review.recommendations[key]?.every((rec) => {
                if (rec.review.length > 0) {
                    return rec.review[0].status === 'accepted' || rec.review[0].status === 'staff_implemented' || (rec.review[0].status === 'rejected' && rec.review[0].notes);
                }
                return true;
            });
        });
    }, [review.items, review.recommendations, isReadOnly]);

    async function checkoutReview() {
        await createCheckoutLog({
            draftId: draftId,
            action: 'checked_out'
        });
    }

    async function handleUpdate(review, recommendation, newStatus, notes) {
        if (differenceInMinutes(new Date(), timeOfLastUpdate) >= 15) {
            setIsShowingTimeoutDialog(true);
            logAction('user_checkout_timed_out', { draftId: draftId });
            return;
        }

        await updateReview({
            review: review,
            draftId: draftId,
            recommendation: recommendation,
            newStatus: newStatus,
            notes: notes
        }).unwrap().then(() => {
            setTimeOfLastUpdate(new Date());
        });
    }

    async function handleSubmitReview() {
        await updateDraft({
            draftId: review.id,
            status: 'applied'
        }).unwrap().then(() => {
            createCheckoutLog({
                draftId: draftId,
                action: 'checked_in',
                reason: 'review_submitted',
            });
        });
        setIsShowingSubmitConfirmation(false);
    }

    async function logAction(action, data) {
        await logUserAction({
            action: action,
            data: data
        });
    }

    function handleViewChange(view) {
        if (view !== null) {
            setView(view);
        }
    }

    return (
        <Stack spacing={3} pb={6}>
            <Stack spacing={3} position='sticky' sx={{ top: '64', zIndex: 1, backgroundColor: '#F7F9FC' }}>
                <Stack 
                    direction='row' 
                    justifyContent='space-between' 
                    alignContent='center'
                    px={6}
                >
                    <Stack spacing={1}>
                        <Stack direction='row' spacing={1.5}>
                            <Typography variant='heavy' sx={{ fontSize: '20px' }}><strong>Physician: </strong>Dr. {review.card?.doctor.name}</Typography>
                            <Typography variant='heavy' sx={{ fontSize: '20px' }}><strong>Card: </strong>{review.card?.name} {review.card?.external_card_id && `(${review.card?.external_card_id})`}</Typography>
                        </Stack>
                        { review.status === 'applied' ? 
                            <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Review Completed: </strong>{format(parseISO(review.staff_review_completion_time), 'MM/dd/yyyy hh:mm a')}</Typography> :
                            <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Reviewing As: </strong>{user?.name}</Typography>
                        }                   
                    </Stack>
                    <Stack spacing={2}>
                        <Stack direction='row' spacing={1} justifyContent='space-between' alignItems='center'>
                            <Button variant='contained' disabled={isReadOnly} size='small' style={{ alignSelf: 'center' }} startIcon={<FlagRounded />} onClick={() => setIsShowingFlagDialog(true)}>Flag Review</Button>
                            <Tooltip title={canSubmit ? '' : tooltipText} arrow>
                                {/* This wrapper div is required to display the tooltip when the button is disabled */}
                                <div>
                                    <Button 
                                        variant='contained' 
                                        size='small' 
                                        style={{ alignSelf: 'center' }} 
                                        disabled={!canSubmit}
                                        onClick={() => setIsShowingSubmitConfirmation(true)}
                                    >Submit Review</Button>
                                </div>
                            </Tooltip>
                        </Stack>
                        <ToggleButtonGroup
                            color='primary'
                            value={view}
                            exclusive
                            onChange={(e, value) => handleViewChange(value)}
                            sx={{ borderRadius: '0px' }}
                        >
                            <ToggleButton value='only_changes'>Changes to Review</ToggleButton>
                            <ToggleButton value='full_card'>All Card Items</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <SubmitReviewConfirmation open={isShowingSubmitConfirmation} handleClose={() => setIsShowingSubmitConfirmation(false)} handleSubmit={handleSubmitReview} />
                    <FlagReviewDialog open={isShowingFlagDialog} handleClose={() => setIsShowingFlagDialog(false)} draftId={draftId} />
                </Stack>
                <CheckedOutCardDialog 
                    draft={review}
                    open={isShowingCheckedOutDialog} 
                    handleClose={() => setIsShowingCheckedOutDialog(false)} 
                    returnToActiveReviews={() => navigate('/staffReview/v1/activeReviews')} 
                />
                <CheckoutTimeoutDialog
                    draft={review}
                    open={isShowingTimeoutDialog}
                    handleRefresh={() => window.location.reload()}
                />
                <Divider orientation='horizontal' />
            </Stack>
            { view === 'only_changes' && 
                <Stack px={6}>
                    <ChangesOnlyView
                        review={review} 
                        handleUpdate={handleUpdate} 
                        isReadOnly={isReadOnly}
                    /> 
                </Stack>
            }
            { view === 'full_card' && 
                <Stack height='100%' px={6} spacing={2}>
                    { Object.keys(review.items ?? {}).map((key, index) => (
                        <Category categoryName={key} review={review} key={index} handleUpdate={handleUpdate} disabled={isReadOnly} />
                    ))}
                </Stack>
            }
        </Stack>
    )
}


function SubmitReviewConfirmation(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Submit Review</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to submit this review?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={props.handleClose}>Cancel</Button>
                <Button variant='contained' onClick={props.handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}


function Category(props) {
    const numberToReview = useMemo(() => {
        return props.review.recommendations[props.categoryName]?.filter((rec) => rec.review[0]?.status === 'open').length ?? 0;
    }, [props.review.recommendations, props.categoryName]);

    const [isOpen, setIsOpen] = useState(numberToReview > 0 ? true : false);

    const allRows = useMemo(() => {
        var rows = [];

        Object.keys(props.review.items[props.categoryName]).forEach((item) => {
            rows.push(props.review.items[props.categoryName][item]);
        });

        props.review.recommendations[props.categoryName]?.filter((rec) => rec.type === 'addition' && rec.status === 'accepted').forEach((rec) => {
            rows.push(rec.item);
        });

        return rows;
    }, [props.review.items, props.review.recommendations, props.categoryName]);

    return (
        <Stack spacing={2} px={2} py={1} sx={{ backgroundColor: 'white', borderRadius: '4px' }}>
            <Stack spacing={1} width='100%' sx={{ backgroundColor: 'white', borderRadius: '4px', padding: '16px' }}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>{props.categoryName} { !props.disabled && `: ${numberToReview} Change${ numberToReview === 1 ? '' : 's'} to Review`}</Typography>
                    <IconButton onClick={() => setIsOpen(!isOpen)}>
                        { isOpen ?  <ExpandMoreRounded /> : <ExpandLessRounded />}
                    </IconButton>
                </Stack>
                <Collapse in={isOpen} timeout='auto' unmountOnExit>
                    <Stack borderLeft='1px solid rgba(0, 0, 0, 0.5)' borderRight='1px solid rgba(0, 0, 0, 0.5)' borderTop='1px solid rgba(0, 0, 0, 0.5)'>
                        <TableHeader />
                        { allRows.length > 0 && 
                            allRows.map((item, index) => (
                                <ReviewRow 
                                    key={index}
                                    index={index}
                                    item={item} 
                                    recommendation={props.review.recommendations[props.categoryName]?.find((rec) => rec.item.id === item.id)}
                                    draft={props.review} 
                                    handleUpdate={props.handleUpdate}
                                    disabled={props.disabled}
                                />
                            ))
                        }
                    </Stack>
                </Collapse>
            </Stack>
        </Stack>
    )
}

export default StaffReview;
