import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import StaffReviewStatBox from './StaffReviewStatBox';
import { NumericFormat } from 'react-number-format';
import { theme } from '../../../theme';
import PhysicianGroups from './PhysicianGroups';
import useStaffReviewUser from '../../Hooks/useStaffReviewUser';
import { useGetCardsForReviewQuery, useGetUserStatisticsQuery } from '../../../api/staffReviewSlice';
import LoadingIndicator from '../../LoadingIndicator';

function StaffReviewLandingPage() {
    const { staffReviewUser: user } = useStaffReviewUser();
    const { data: drafts = [], isLoading } = useGetCardsForReviewQuery(user?.id, { skip: !user });
    const { data: userStats = {}, isSuccess } = useGetUserStatisticsQuery(user?.id, { skip: !user });

    const physicianDraftGroupings = useMemo(() => {
        const physicianData = {};
        drafts.forEach((draft) => {
            if (!physicianData[draft.card.doctor.id]) {
                physicianData[draft.card.doctor.id] = {
                    physician: draft.card.doctor,
                    drafts: [],
                    numOverdue: 0,
                };
            }
            physicianData[draft.card.doctor.id].drafts.push(draft);
            physicianData[draft.card.doctor.id].numOverdue += draft.num_overdue_reviews;
        });
        return physicianData;
    }, [drafts]);

    if (isLoading) {
        return (
            <Stack px={{ xs: 2, md: 6 }} pb={3} sx={{ height: '100%', overflow: 'auto' }}>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='bold' sx={{ fontSize: '20px' }}>Physician Requests to Review</Typography>
                    <Stack spacing={1}>
                        <Typography variant='heavy' sx={{ fontSize: '20px' }}>{user?.name}</Typography>
                        <Typography variant='normal' color={theme.palette.subtext.main} sx={{ fontSize: '16px' }}>{user?.hospital?.name}</Typography>
                    </Stack>
                </Stack>
                <Stack width="100%" height="100%">
                    <LoadingIndicator variant='reviews'/>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack px={4} py={2} spacing={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='bold' alignSelf='end' sx={{ fontSize: '20px' }}>Physician Requests to Review</Typography>
                <Stack textAlign='end'>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>{user?.name}</Typography>
                    <Typography variant='normal' color={theme.palette.subtext.main} sx={{ fontSize: '14px' }}>{user?.hospital?.name}</Typography>
                </Stack>
            </Stack>
            <Stack direction='row' width='100%' sx={{
                border: '1px solid',
                borderRadius: '8px',
                borderColor: theme.palette.border.main,
            }}>
                <StaffReviewStatBox 
                    title='Total Requests Awaiting Review' 
                    isLoaded={isSuccess}
                    value={userStats.total_open_reviews} 
                    sx={{ borderTopLeft: '16px', borderBottomLeft: '16px' }} 
                />
                <StaffReviewStatBox 
                    title='Total Overdue' 
                    value={userStats.total_overdue_reviews} 
                    isLoaded={isSuccess}
                />
                <StaffReviewStatBox 
                    title='Due by End of Day' 
                    value={userStats.total_due_by_end_of_day} 
                    isLoaded={isSuccess}
                />
                <StaffReviewStatBox 
                    title='Average Review Time' 
                    value={userStats.average_review_time + ' days'} 
                    isLoaded={isSuccess}
                    tooltip='This represents the average time it takes from when an optimization is ready for your review to when the review is completed, across all optimizations assigned to you.'
                />
                <StaffReviewStatBox 
                    title='Your Total Estimated Savings Captured' 
                    value={
                        <NumericFormat value={userStats.total_savings_captured / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={' / year'}/> 
                    } 
                    isLoaded={isSuccess}
                    sx={{ borderTopRight: '16px', borderBottomRight: '16px' }} 
                    tooltip='This is the estimated annual impact of the preference card optimizations which you have approved.  This calculation is based on item cost, historical case counts per card and the item’s open / hold status.'
                />
            </Stack>
            <PhysicianGroups 
                draftGroupings={physicianDraftGroupings} 
                status='active' 
                isLoading={isLoading}
            />
        </Stack>
    );
}

export default StaffReviewLandingPage;
