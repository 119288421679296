import { Box } from '@mui/system';
import React from 'react';
import { Divider, Stack } from '@mui/material';
import CardEntry from './CardEntry';
import { theme } from '../../../theme';

function PhysicianCardArea(props) {
    return (
        <Stack 
            direction='row' 
            width='100%' 
            backgroundColor={theme.palette.background.darkGray}
            borderTop='1px solid'
            borderColor={theme.palette.border.main}
            style={{
                '&:lastChild': {
                    borderBottomRightRadius: '8px',
                    borderBottomLeftRadius: '8px',
                }
            }}
        >
            <Box width='15%' />
            <Stack width='85%' divider={<Divider />}>
                { props.drafts.map((draft) => {
                    return <CardEntry key={draft.id} draft={draft} />
                })}
            </Stack>
        </Stack>
    );
}

export default PhysicianCardArea;
