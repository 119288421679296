import React from 'react';
import { Stack, Typography, Tooltip } from '@mui/material';
import { LockRounded, InfoOutlined } from '@mui/icons-material';
import { theme } from '../../../theme';

function DropdownItem(props) {
    return (
        <Stack spacing={0.5} direction='row' alignItems='center' color={props.disabled ? theme.palette.text.disabled : props.color}>
            { props.disabled ? 
                <LockRounded fontSize='small' /> :
                props.icon
            }
            <Typography variant='normal' fontSize='14px'>{props.text}</Typography>
            { props.disabled && 
                <Tooltip title='This physician requested card update has been or is in the process of being reviewed by another member of the card management team.'>
                    <InfoOutlined fontSize='small' />
                </Tooltip>
            }
        </Stack>
    );
}

export default DropdownItem;
