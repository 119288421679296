import { MenuItem,  Select } from '@mui/material';
import React, { useState } from 'react';
import { theme } from '../../../theme';
import { CheckRounded,  CloseRounded, HowToRegRounded } from '@mui/icons-material';
import DropdownItem from './DropdownItem';

function ActionDropdown(props) {
    const [action, setAction] = useState(props.review.status);

    function handleChange(e) {
        setAction(e.target.value);
        props.handleUpdate(props.review, props.recommendation, e.target.value);
    }

    const actionDisplays = {
        'open': <DropdownItem text='Action Required' color={theme.palette.blue.main} disabled={props.disabled} />,
        'accepted': <DropdownItem text='Approved' color={theme.palette.green.main} disabled={props.disabled} icon={<CheckRounded />} />,
        'rejected': <DropdownItem text='Denied' color={theme.palette.red.main} disabled={props.disabled} icon={<CloseRounded />} />,
        'staff_implemented': <DropdownItem text='Approved & Implemented' color={theme.palette.green.main} disabled={props.disabled} icon={<HowToRegRounded />} />,
    }

    return (
        <Select
            value={action}
            onChange={handleChange}
            sx={{
                borderRadius: '0px',
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderRadius: '0px'
                },
            }}
            renderValue={() => actionDisplays[action]}
            inputProps={ props.disabled ? { IconComponent: () => null, sx: { pr: '8 !important' }} : {} }
            disabled={props.disabled}
        >
            <MenuItem value='open'>Action Required</MenuItem>
            <MenuItem value='accepted'>Approved</MenuItem>
            <MenuItem value='rejected'>Denied</MenuItem>
            <MenuItem value='staff_implemented'>Approved & Implemented</MenuItem>
        </Select>
    );
}

export default ActionDropdown;

