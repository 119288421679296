import { createTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';

const palette = createPalette({
    text: {
        primary: '#000000',
        disabled: '#00000061',
    },
    open: {
        main: '#34C759'
    },
    hold: {
        main: '#8E8E93'
    },
    blue: {
        main: '#007AFF',
        heavy: '#007BFF',
        border: '#2E7BFF'
    },
    red: {
        main: '#CF093D',
        leaderboard: '#CF093D',
        compliance: '#CF093D',
    },
    green: {
        main: '#00897C',
        leaderboard: '#2C9A85',
        taskBackground: '#0585781F',
        taskText: '#058578',
    },
    subtext: {
        main: '#00000099'
    },
    background: {
        main: '#F7F9FC',
        white: '#FFFFFF',
        itemSwap: '#EDEDED',
        disabled: '#D6D6D6',
        neutral: '#0000000F',
        positive: '#F0F0F0',
        negative: '#FF3B3021',
        categoryDrawer: '#00000012',
        unreviewedProgress: '#D9D9D9',
        deniedProgress: '#656565',
        darkGray: '#F5F5F5'
    },
    itemSwapChip: {
        background: {
            positive: '#25857821',
            negative: '#CF083D1F'
        },
        text: {
            positive: '#258578',
            negative: '#CF083D'
        }
    },
    campaignMonitoring: {
        green: {
            background: '#BBFFDA',
            text: '#00612D',
        },
        red: {
            background: '#FFD7D7',
            text: '#980000',
        }
    },
    border: {
        main: '#0000001F'
    },
    primary: {
        main: '#007BFF',
    },
});

export const theme = createTheme({
    palette,
    typography: {
        light: {
            'fontFamily': 'Roboto',
            'fontWeight': 300,
            'opacity': 0.75,
        },
        normal: {
            'fontFamily': 'Roboto',
            'fontWeight': 400,
            'opacity': 1.0
        },
        heavy: {
            'fontFamily': 'Roboto',
            'fontWeight': 500,
            'opacity': 1.0
        },
        bold: {
            'fontFamily': 'Roboto',
            'fontWeight': 700,
            'opacity': 1.0
        }
    },
    components: {
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    lineHeight: '1',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
                contained: {
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    backgroundColor: palette.background.white,
                    color: palette.subtext.main,
                    '&:hover': {
                        backgroundColor: palette.background.white
                    }
                },
                outlined: {
                    borderColor: 'rgb(0, 123, 255, 1.0)'
                },
                blueContained: {
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    backgroundColor: palette.blue.main,
                    color: palette.background.white,
                    '&:hover': {
                        backgroundColor: palette.blue.heavy
                    },
                    '&:disabled': {
                        backgroundColor: palette.background.disabled
                    }
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: 'black',
                    borderBottom: 'none',
                },
                head: {
                    color: 'black',
                    borderBottom: '2px solid rgb(224, 224, 224)',
                    borderTop: '2px solid rgb(224, 224, 224)',
                }
            }
        },
        MuiTableRow: {
            styleOverrides: { 
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(217, 217, 217, 0.3)'
                    },
                },     
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    '& .MuiTableRow-root': {
                        '&:last-child': {
                            '& .MuiTableCell-root': {
                                borderBottom: 'none'
                            }
                        },
                        '& .MuiTableCell-root': {
                            borderBottom: '1px solid rgb(224, 224, 224)'
                        }
                    }
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '18px',
                    fontWeight: '500',
                    opacity: '1.0',
                    fontFamily: 'Roboto',
                    paddingTop: '16px',
                    paddingBottom: '8px',
                }
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight: '400',
                    opacity: '1.0',
                    fontFamily: 'Roboto',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    color: 'rgba(0, 0, 0, 1.0)'
                }
            }
        },
        MuiStepConnector:{
            styleOverrides: {
                lineVertical: {
                    padding: '0px',
                    borderLeftWidth: '3px',
                    borderLeftColor: palette.background.white,
                    minHeight: '16px',
                }
            }
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    },
    shadows: [
        'none',
        '0px 2px 4px #00000012',
        '0px 3px 6px #00000029',
        '4px 5px 4px #00000012',
        '0px 16px 48px #0000002B',
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        ...Array(17).fill('none')
    ],
});
