import React from 'react';
import HeadlineTile from '../HeadlineTile';
import { Box, Stack, useTheme } from '@mui/system';
import { Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';


function PersonalComplianceTile(props) {
    const theme = useTheme();

    return (
        <HeadlineTile className='pendo-compliance-deficiency'>
            <LogOnEnter action='viewed_personal_compliance_tile' data={{ category: props.complianceCategory, debt: props.complianceDebt }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Stack>
                        <Typography variant='heavy' sx={{ fontSize: '16px' }}>My Annual Out of Contract Spend</Typography>
                        <Typography variant='normal' sx={{ fontSize: '12px' }}>{props.complianceCategory}</Typography>
                    </Stack>
                    <Box px={1} py={0.5} sx={{ border: '1px solid #a5a5a5', borderRadius: '4px', backgroundColor: theme.palette.background.darkGray }}>
                        <Typography variant='heavy' sx={{ fontSize: '16px', color: props.complianceDebt > 0 ? `${theme.palette.red.leaderboard}` : `${theme.palette.green.leaderboard}` }}>
                            <NumericFormat value={props.complianceDebt / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} />
                        </Typography>
                    </Box>
                </Stack>
            </LogOnEnter>
        </HeadlineTile>
    );
}


export default PersonalComplianceTile;
