import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

function OptimizationTypes(props) {
    return (
        <Paper elevation={2} sx={{ width: '23%' }}>
            <Stack p={3} spacing={2}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='light' sx={{ fontSize: '18px' }}>Optimization Types</Typography>
                    <Tooltip title='Optimization types are used to determine which types of optimizations will be shown to physicians.'>
                        <InfoOutlined style={{ opacity: 0.5 }} />
                    </Tooltip>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Item Removals</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.removals} onChange={() => props.setSettings({ ...props.settings, removals: !props.settings.removals })} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Item Updates</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.updates} onChange={() => props.setSettings({ ...props.settings, updates: !props.settings.updates })} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Item Additions</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.additions} onChange={() => props.setSettings({ ...props.settings, additions: !props.settings.additions })} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Item Swaps</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.swaps} onChange={() => props.setSettings({ ...props.settings, swaps: !props.settings.swaps })} />
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Contract Compliance</Typography>
                    <Checkbox disabled={props.disabled} checked={props.settings.compliance} onChange={() => props.setSettings({ ...props.settings, compliance: !props.settings.compliance })} />
                </Stack>
            </Stack>
        </Paper>
    )
}

export default OptimizationTypes;
