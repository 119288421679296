import React, { useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';

function ReviewerFeedback(props) {
    const [feedback, setFeedback] = useState(props.review.notes);

    function handleSaveTextChange() {
        props.handleUpdate(props.review, props.recommendation, 'rejected', feedback);
    }

    return (
        <Stack direction='row' py={1} px={2} spacing={2} alignItems='center' backgroundColor='white' borderRadius='8px' width='100%'>
            <Typography variant='bold' fontSize='12px' minWidth='fit-content'>Reviewer Feedback: </Typography>
            <TextField
                variant='standard'
                placeholder='Please give feedback on why you denied this physician request.'
                fullWidth
                multiline
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                size='small'
                disabled={props.disabled}
            />
            <Button 
                onClick={handleSaveTextChange} 
                variant='text' 
                color='primary' 
                disabled={!feedback || feedback === props.review.notes || props.disabled} 
                sx={{ borderRadius: '4px' }}
            >
                Save
            </Button>
        </Stack>
    );
}

export default ReviewerFeedback;
