import { Stack, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../../theme';
import { NumericFormat } from 'react-number-format';
import OptimizationContext from '../../PhysicianDashboard/CardReview/Optimizations/OptimizationContext';
import { ArrowForwardRounded, BoltRounded } from '@mui/icons-material';
import ItemChipSection from '../../PhysicianDashboard/CardReview/ItemChipSection';
import ItemDetail from '../../PhysicianDashboard/CardReview/ItemDetail';
import ItemSwapChipSection from '../../PhysicianDashboard/ItemSwaps/ItemSwapChipSection';

function StaffReviewItemDetails(props) {
    var optimizationText = {
        'addition': 'Add This Item',
        'removal': 'Remove This Item',
        'update': 'Adjust This Item',
    }

    return (
        <Stack px={2} pb={2} justifyContent='space-evenly' backgroundColor={theme.palette.background.darkGray}>
            <Stack direction='row' spacing={2}>
                <Stack p={2} spacing={1} backgroundColor='white' borderRadius='8px' width='50%'>
                    <Typography variant='bold' fontSize='14px'>Item Details</Typography>
                    <Typography variant='normal' fontSize='12px'>
                        {props.item.name}
                    </Typography>
                    <Typography variant='normal' fontSize='12px'>
                        {props.item.vendor.name} | {props.item.part_number} | <NumericFormat value={props.item.cost / 100} displayType='text' prefix='$' suffix={' / Each'} thousandSeparator={','} fixedDecimalScale decimalScale={2} />
                    </Typography>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Typography variant='normal' fontSize='12px'>Original Card Qtys: </Typography>
                        <ItemDetail type='quantity' quantityType='open' data={props.item.open_quantity} />
                        <ItemDetail type='quantity' quantityType='hold' data={props.item.hold_quantity} />
                    </Stack>
                    { (props.recommendation?.type === 'swap' || props.recommendation?.type === 'compliance') &&
                        <ItemSwapChipSection recommendation={props.recommendation} variant='current' />
                    }
                </Stack>
                { props.recommendation && props.recommendation.type !== 'swap' && props.recommendation.type !== 'compliance' &&
                    <Stack p={2} backgroundColor='white' borderRadius='8px' width='50%'>
                        <Stack direction='row' spacing={0.5} alignItems='center' pb={1.5}>
                            <BoltRounded fontSize='small' />
                            <Typography variant='heavy' sx={{ fontSize: '14px' }}><strong>Optimization: {optimizationText[props.recommendation.type]}</strong></Typography>
                        </Stack>
                        <ItemChipSection item={props.item} recommendation={props.recommendation} />
                        <OptimizationContext item={props.item} recommendation={props.recommendation} excludeBenefit />
                    </Stack>
                }
                { (props.recommendation?.type === 'swap' || props.recommendation?.type === 'compliance') &&
                    <>
                        <ArrowForwardRounded style={{ color: theme.palette.subtext.main, alignSelf: 'center' }} />
                        <Stack p={2} spacing={1} backgroundColor='white' borderRadius='8px' width='50%'>
                            <Typography variant='heavy' sx={{ fontSize: '14px' }}><strong>New Item Details</strong></Typography>
                            <Typography variant='normal' fontSize='12px'>
                                {props.recommendation.recommended_item.name}
                            </Typography>
                            <Typography variant='normal' fontSize='12px'>
                                {props.recommendation.recommended_item.vendor.name} | {props.recommendation.recommended_item.part_number} | <NumericFormat value={props.recommendation.recommended_item.cost / 100} displayType='text' prefix='$' suffix={' / Each'} thousandSeparator={','} fixedDecimalScale decimalScale={2} />
                            </Typography>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Typography variant='normal' fontSize='12px'>New Card Qtys: </Typography>
                                <ItemDetail type='quantity' quantityType='open' data={props.recommendation.open_quantity} />
                                <ItemDetail type='quantity' quantityType='hold' data={props.recommendation.hold_quantity} />
                            </Stack>
                            <ItemSwapChipSection recommendation={props.recommendation} variant='recommended' potentialSavings={props.recommendation.recommendation_value} />
                        </Stack>
                    </>
                }
            </Stack>
        </Stack>
    );
}

export default StaffReviewItemDetails;

