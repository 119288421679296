import '@fontsource/roboto';
import { CssBaseline, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { theme } from '../theme';
import ChangePassword from './Auth/ChangePassword';
import LockedAccount from './Auth/LockedAccount';
import Login from './Auth/Login';
import PasswordRecovery from './Auth/PasswordRecovery';
import PhysicianDashboardAuthRedirect from './Auth/PhysicianDashboardAuthRedirect';
import RequireAuth from './Auth/RequireAuth';
import RequirePhysicianDashboardAuth from './Auth/RequirePhysicianDashboardAuth';
import RequireStaffReviewAuth from './Auth/RequireStaffReviewAuth';
import RequireSuperAccess from './Auth/RequireSuperAccess';
import StaffReviewAuthRedirect from './Auth/StaffReviewAuthRedirect';
import DraftPreview from './Campaign/CampaignPreview/DraftPreview/DraftPreview';
import Campaigns from './Campaign/Campaigns';
import CreateCampaign from './Campaign/CreateCampaign';
import EditCampaign from './Campaign/EditCampaign';
import CardList from './Card/CardList';
import CardTemplate from './Card/CardTemplate';
import CreateCard from './Card/CreateCard/CreateCard';
import LandingPage from './Demo/LandingPage';
import Header from './Header';
import { AuthProvider } from './Hooks/useAuth';
import { UserContextProvider } from './Hooks/useUser';
import CreateItem from './Inventory/CreateItem';
import Inventory from './Inventory/Inventory';
import ItemDetails from './Inventory/ItemDetails';
import OrganizationConfiguration from './SuperSettings/Organizations/OrganizationConfiguration';
import OrganizationView from './SuperSettings/Organizations/OrganizationView';
import SuperView from './SuperSettings/Organizations/SuperView';
import CreatePhysician from './Physician/CreatePhysician';
import EditPhysician from './Physician/EditPhysician';
import Physician from './Physician/Physician';
import CardReview from './PhysicianDashboard/CardReview/CardReview';
import ComplianceSwaps from './PhysicianDashboard/ItemSwaps/Compliance/ComplianceSwaps';
import ItemSwaps from './PhysicianDashboard/ItemSwaps/ItemSwaps';
import PhysicianDashboard from './PhysicianDashboard/PhysicianDashboard';
import Sidebar from './Sidebar/Sidebar';
import StaffReviewSidebar from './Sidebar/StaffReviewSidebar';
import PastReviewsCardList from './StaffReviewPortal/PastReviewsCardList';
import StaffReview from './StaffReviewPortal/StaffReview';
import StaffReviewCardList from './StaffReviewPortal/StaffReviewCardList';
import ManageCategories from './SuperSettings/Categories/ManageCategories';
import CreateHospital from './SuperSettings/Hospital/CreateHospital';
import UpdateHospitalSetup from './SuperSettings/Hospital/HospitalConfiguration';
import Ingestion from './SuperSettings/Ingestion/Ingestion';
import IngestionImport from './SuperSettings/Ingestion/IngestionImport';
import Conversation from './SuperSettings/PhysicianTexting/Conversation';
import PhysicianTexting from './SuperSettings/PhysicianTexting/PhysicianTexting';
import ReviewerAssignment from './SuperSettings/StaffReviewManagement/ReviewerAssignment/ReviewerAssignment';
import SuperSettings from './SuperSettings/SuperSettings';
import PasswordReset from './SuperSettings/UserManagement/PasswordReset';
import CreateUser from './User/CreateUser';
import EditUser from './User/EditUser';
import UsersView from './User/UsersView';
import CampaignLandingPage from './PhysicianDashboard/LandingPage/CampaignLandingPage';
import BulkTexting from './Campaign/CampaignTexting/BulkTexting';
import UserManagement from './SuperSettings/Organizations/UserManagement/UserManagement';
import CampaignMonitoring from './CampaignMonitoring/CampaignMonitoring';
import EmailUnsubscribe from './Auth/EmailUnsubscribe';
import StaffReviewLandingPage from './StaffReviewPortal/v2/StaffReviewLandingPage';
import StaffReviewCardView from './StaffReviewPortal/v2/StaffReviewCardView';
import CompletedReviews from './StaffReviewPortal/v2/CompletedReviews';

function App() {

    return (
        <AuthProvider>
            <UserContextProvider>
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path='/recovery' element={<PasswordRecovery />} />
                        <Route path='/password/reset/:token' element={<ChangePassword />} />
                        <Route path='/lockedAccount' element={<LockedAccount />} />
                        <Route path='/demo/register' element={<LandingPage />} />
                        <Route path='/unsubscribe/:userId' element={<EmailUnsubscribe />} />
                        <Route path='/physicianDashboard/auth' element={<PhysicianDashboardAuthRedirect />} />
                        <Route path='/staffReview/auth' element={<StaffReviewAuthRedirect />} />
                        
                        <Route element={
                            <DashboardLayout />
                        }>
                            <Route path='/physician/drafts/:draftId' element={
                                <RequirePhysicianDashboardAuth>
                                    <CardReview />
                                </RequirePhysicianDashboardAuth>
                            }/>
                            <Route path='/physician/dashboard' element={
                                <PhysicianDashboardAuthRedirect />
                            }/>
                            <Route path='/physicianDashboard/headline/:campaignId' element ={
                                <RequirePhysicianDashboardAuth>
                                    <PhysicianDashboard />
                                </RequirePhysicianDashboardAuth>
                            }/>
                            <Route path='/physician/:physicianId/swaps/:campaignId' element={
                                <RequirePhysicianDashboardAuth>
                                    <ItemSwaps />
                                </RequirePhysicianDashboardAuth>
                            } />
                            <Route path='/physician/:physicianId/compliance/:campaignId' element={
                                <RequirePhysicianDashboardAuth>
                                    <ComplianceSwaps />
                                </RequirePhysicianDashboardAuth>
                            } />
                        </Route>
                        <Route element={ 
                            <LandingPageLayout />
                        }>
                            <Route path='/physician/dashboard/landing' element={
                                <RequirePhysicianDashboardAuth>
                                    <CampaignLandingPage />
                                </RequirePhysicianDashboardAuth>
                            } />
                        </Route>
                        <Route element={
                            <StaffReviewLayout />
                        }>
                            <Route path='/staffReview/activeReviews' element={
                                <RequireStaffReviewAuth>
                                    <StaffReviewLandingPage />
                                </RequireStaffReviewAuth>
                            } />
                            <Route path='/staffReview/pastReviews' element={
                                <RequireStaffReviewAuth>
                                    <CompletedReviews />
                                </RequireStaffReviewAuth>
                            } />
                            <Route path='/staffReview/:draftId' element={
                                <RequireStaffReviewAuth>
                                    <StaffReviewCardView />
                                </RequireStaffReviewAuth>
                            } />
                            <Route path='/staffReview/v1/activeReviews' element={
                                <RequireStaffReviewAuth>
                                    <StaffReviewCardList />
                                </RequireStaffReviewAuth>
                            } />
                            <Route path='/staffReview/v1/:draftId' element={
                                <RequireStaffReviewAuth>
                                    <StaffReview />
                                </RequireStaffReviewAuth>
                            } />
                            <Route path='/staffReview/v1/pastReviews' element={
                                <RequireStaffReviewAuth>
                                    <PastReviewsCardList />
                                </RequireStaffReviewAuth>
                            } />
                        </Route>
                        <Route element={
                            <RequireAuth>
                                <Layout />
                            </RequireAuth>
                        }>
                            <Route path='/cards' element={
                                <RequireAuth>
                                    <CardList />
                                </RequireAuth>
                            } />
                            <Route path='/cards/create' element={
                                <RequireAuth>
                                    <CreateCard />
                                </RequireAuth>
                            } />
                            <Route path='/cards/:cardID' element={
                                <RequireAuth>
                                    <CardTemplate />
                                </RequireAuth>
                            } />
                            <Route path='/inventory' element={
                                <RequireAuth>
                                    <Inventory />
                                </RequireAuth>
                            } />
                            <Route path='/inventory/:itemId/details' element={
                                <RequireAuth>
                                    <ItemDetails />
                                </RequireAuth>
                            } />
                            <Route path='/inventory/create' element={
                                <RequireAuth>
                                    <CreateItem />
                                </RequireAuth>
                            } />
                            <Route path='/users' element={
                                <RequireAuth>
                                    <UsersView />
                                </RequireAuth>
                            } />
                            <Route path='/physicians' element={
                                <RequireAuth>
                                    <Physician />
                                </RequireAuth>
                            } />
                            <Route path='/physicians/create' element={
                                <RequireAuth>
                                    <CreatePhysician />
                                </RequireAuth>
                            } />
                            <Route path='/physicians/:physicianId' element={
                                <RequireAuth>
                                    <EditPhysician />
                                </RequireAuth>
                            } />
                            <Route path='/users/create' element={
                                <RequireAuth>
                                    <CreateUser />
                                </RequireAuth>
                            } />
                            <Route path='/users/:userId' element={
                                <RequireAuth>
                                    <EditUser />
                                </RequireAuth>
                            } />
                            <Route path='/campaigns' element={
                                <RequireSuperAccess>
                                    <Campaigns />
                                </RequireSuperAccess>
                            } />
                            <Route path='/campaigns/:campaignId' element={
                                <RequireSuperAccess>
                                    <EditCampaign />
                                </RequireSuperAccess>
                            } />
                            <Route path='/campaigns/:campaignId/texting' element={
                                <RequireSuperAccess>
                                    <BulkTexting />
                                </RequireSuperAccess>
                            } />
                            <Route path='/campaigns/:campaignId/previews/:draftId' element={
                                <RequireSuperAccess>
                                    <DraftPreview />
                                </RequireSuperAccess>
                            } />
                            <Route path='/campaigns/create' element={
                                <RequireSuperAccess>
                                    <CreateCampaign/>
                                </RequireSuperAccess>
                            } />
                            <Route path='/campaignMonitoring/campaigns' element={
                                <RequireSuperAccess>
                                    <CampaignMonitoring />
                                </RequireSuperAccess>
                            } />
                            <Route path='/physicianTexting' element={
                                <RequireSuperAccess>
                                    <PhysicianTexting />
                                </RequireSuperAccess>
                            } />
                            <Route path='/physicians/:physicianId/physicianTexting' element={
                                <RequireSuperAccess>
                                    <Conversation />
                                </RequireSuperAccess>
                            } />
                            <Route path='*' element={<Navigate to='/cards' replace />} />
                        </Route>
                        <Route element={
                            <RequireAuth>
                                <SuperSettingsLayout />
                            </RequireAuth>
                        }>
                            <Route path='/superSettings/organizations' element={
                                <RequireSuperAccess>
                                    <SuperView />
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/organizations/:organizationId' element={
                                <RequireSuperAccess>
                                    <OrganizationView />
                                </RequireSuperAccess>
                            } />
                            <Route path='superSettings/organizations/create' element={
                                <RequireSuperAccess>
                                    <OrganizationConfiguration isCreating={true} />
                                </RequireSuperAccess>
                            } />
                            <Route path='superSettings/organizations/:organizationId/users' element={
                                <RequireSuperAccess>
                                    <UserManagement />
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/:hospitalId' element={
                                <RequireSuperAccess>
                                    <SuperSettings/>
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/create' element={
                                <RequireSuperAccess>
                                    <CreateHospital/>
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/:hospitalId/manageHospital' element={
                                <RequireSuperAccess>
                                    <UpdateHospitalSetup/>
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/:hospitalId/passwordResets' element={
                                <RequireSuperAccess>
                                    <PasswordReset />
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/:hospitalId/manageCategories' element={
                                <RequireSuperAccess>
                                    <ManageCategories/>
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/:hospitalId/imports' element={
                                <RequireSuperAccess>
                                    <Ingestion />
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/:hospitalId/imports/:importId' element={
                                <RequireSuperAccess>
                                    <IngestionImport />
                                </RequireSuperAccess>
                            } />
                            <Route path='/superSettings/hospitals/:hospitalId/reviewerManagement' element={
                                <RequireSuperAccess>
                                    <ReviewerAssignment />
                                </RequireSuperAccess>
                            } />
                        </Route>

                    </Routes>
                </ThemeProvider>
            </UserContextProvider>
        </AuthProvider>
    )
}

function Layout() {
    const drawerWidth = 250;
    const toolbarHeight = 64
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', overflow: 'hidden', backgroundColor: '#f7f9fc' }}>
            <CssBaseline />
            <Header drawerWidth={drawerWidth} setOpenSidebar={setOpen} />
            <Sidebar drawerWidth={drawerWidth} open={open} setOpen={setOpen} />
            <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                <Toolbar />
                <Box component='main' sx={{ width: '100%', height: `calc(100% - ${toolbarHeight}px)` }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

function SuperSettingsLayout() {
    const drawerWidth = 250;
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', overflow: 'hidden', backgroundColor: '#f7f9fc' }}>
            <CssBaseline />
            <Header drawerWidth={drawerWidth} setOpenSidebar={setOpen} />
            <Sidebar isSuperSettings={true} drawerWidth={drawerWidth} open={open} setOpen={setOpen} />
            <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                <Toolbar />
                <Box component='main' sx={{ width: '100%', height: 'calc(100% - 64px)' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

function DashboardLayout() {
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#EDEDED' }}>
            <Box sx={{ width: '100%', height: '100%' }}>
                <Box component='main' sx={{ width: '100%', height: '100%' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

function LandingPageLayout() {
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#FFFFFF' }}>
            <Box sx={{ width: '100%', height: '100%' }}>
                <Box component='main' sx={{ width: '100%', height: '100%' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

function StaffReviewLayout() {
    const drawerWidth = 250;
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', overflow: 'hidden', backgroundColor: '#f7f9fc' }}>
            <CssBaseline />
            <Header drawerWidth={drawerWidth} setOpenSidebar={setOpen} />
            <StaffReviewSidebar drawerWidth={drawerWidth} open={open} setOpen={setOpen} />
            <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                <Toolbar />
                <Box component='main' sx={{ width: '100%', height: 'calc(100% - 64px)' }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

export default App;
