import React from 'react';
import { Chip } from '@mui/material';
import { theme } from '../../../theme';

function OverdueChip() {
    return (
        <Chip 
            label='Overdue' 
            size='small'
            style={{ 
                backgroundColor: `${theme.palette.background.negative}`, 
                color: theme.palette.red.main, 
                fontSize: '14px', 
                borderRadius: '4px',
                height: '20px',
            }} 
        />
    );
}

export default OverdueChip;
