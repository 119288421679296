import { KeyboardArrowDown, KeyboardArrowUp, TextsmsRounded } from '@mui/icons-material';
import { Checkbox, IconButton, LinearProgress, Paper, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

function DraftTable(props){
    const drafts = props.drafts ?? {};
    const sortedPhysicians = useMemo(() => {
        return Object.values(props.physicians).sort((a, b) => {
            return a.last_name.localeCompare(b.last_name);
        });
    }, [props.physicians]);

    return(
        <Paper elevation={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width='3%' />
                        <TableCell width='10%'>Physician</TableCell>
                        <TableCell width='17%'>Cards</TableCell>
                        <TableCell width='10%'># of Optimizations</TableCell>
                        <TableCell width='10%'># Excluded</TableCell>
                        <TableCell width='10%'>Final # After Cap</TableCell>
                        <TableCell width='10%'>Case Count</TableCell>
                        { props.campaign.status === 'open' && 
                            <>
                                <TableCell width='10%'>Optimization Opportunity</TableCell>
                                <TableCell width='10%'>Excluded Opportunity</TableCell>
                                <TableCell width='10%'>Expected Max Opp.</TableCell>
                            </>
                        }
                        { props.campaign.status === 'active' && 
                            <>
                                <TableCell width='20%'>Total Savings Opportunity</TableCell>
                                <TableCell width='20%'>Captured Savings</TableCell>
                            </>
                        }
                        <TableCell width='10%'>Efficiency</TableCell>
                    </TableRow>
                </TableHead>
                { props.isLoadingDrafts ? 
                    <LoadingTable campaign={props.campaign} /> :
                    <TableBody>
                        { Object.values(sortedPhysicians).map((physician) => (
                            <Row 
                                key={physician.id} 
                                physician={physician} 
                                drafts={drafts[physician.id]}
                                campaign={props.campaign}
                                isChecked={props.checkedPhysicians?.includes(physician.id) ?? false}
                                checkedPhysicians={props.checkedPhysicians}
                                setCheckedPhysicians={props.setCheckedPhysicians}
                            />
                        ))}
                    </TableBody>
                }
            </Table>
        </Paper>
    )
}

function LoadingTable(props) {
    const rows = [];
    for (var i = 0; i < 10; i++) {
        rows.push(
            <TableRow key={i}>
                <TableCell width='3%'/>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='27%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                { props.campaign.status === 'open' &&
                    <>
                        <TableCell width='10%'>
                            <Skeleton variant='text' />
                        </TableCell>
                        <TableCell width='10%'>
                            <Skeleton variant='text' />
                        </TableCell>
                    </>
                }
                { props.campaign.status === 'active' &&
                    <>
                        <TableCell width='10%'>
                            <Skeleton variant='text' /> 
                        </TableCell>    
                        <TableCell width='10%'>
                            <Skeleton variant='text' />
                        </TableCell>
                    </>
                }
                <TableCell width='10%'>
                    <Skeleton variant='rectangular' width={100} height={8} />
                </TableCell>
            </TableRow>
        )
    }
    return (
        <TableBody>
            { rows }
        </TableBody>
    )
}


function Row(props) {
    const [open, setOpen] = useState(false);
    const physician = props.physician;
    const drafts = props.drafts;
    const reviewableDrafts = drafts.filter((draft) => (draft.cost_reduction_opportunity + draft.swap_opportunity > 0) && !draft.is_excluded);
    const isCampaignPreview = props.campaign.status === 'open';
    const navigate = useNavigate();

    const reviewedDrafts = reviewableDrafts.filter((draft) => draft.status === 'submitted');
    const caseCount = drafts.reduce((total, card) => {
        if (card.usage_stats) {
            return card.usage_stats[0] ? total + card.usage_stats[0].usage_count : total + 1;
        } else {
            return card.yearly_stats ? total + card.yearly_stats.usage_count : total + 1;
        }
        
    }, 0);

    function isRecExcluded(rec, card, campaignSettings) {
        if (campaignSettings.excluded_phrases) {
            for (var phrase of campaignSettings.excluded_phrases) {
                const itemName = rec.item.name ?? rec.item.external_name;
                if (itemName.toLowerCase().includes(phrase.toLowerCase())) {
                    return true;
                }
            }
        }

        var itemCategory = rec.item.category ?? rec.item.external_category;

        if (campaignSettings.excluded_categories.map((category) => category.name).includes(itemCategory)) {
            return true;
        }

        var cardItem = card.items.find((item) => item.id === rec.item.id);

        if (campaignSettings.exclude_zero_quantity_items && cardItem?.pivot.open_quantity === 0 && cardItem?.pivot.hold_quantity === 0) {
            return true;
        }

        if (!campaignSettings.removals && rec.type === 'removal') {
            return true;
        }

        if (!campaignSettings.updates && rec.type === 'update') {
            return true;
        }

        if (campaignSettings.exclude_all_quantity_increases && rec.type === 'update' && (rec.open_quantity + rec.hold_quantity) > (cardItem?.pivot.open_quantity + cardItem?.pivot.hold_quantity)) {
            return true;
        }

        if (campaignSettings.exclude_open_increases && rec.type === 'update' && (rec.open_quantity > cardItem?.pivot.open_quantity)) {
            return true;
        }

        if (!campaignSettings.additions && rec.type === 'addition') {
            return true;
        }

        if (!campaignSettings.swaps && rec.type === 'swap') {
            return true;
        }

        if (!campaignSettings.compliance && rec.type === 'compliance') {
            return true;
        }

        return false;
    }

    const cardStats = useMemo(() => {
        var groupedCards = {};

        drafts.forEach((card) => {
            var optimizationOpportunity = 0;
            var numExcludedRecs = 0;
            var excludedOpportunity = 0;
            var remainingRecs = 0;
            var remainingOpportunity = 0;

            var includedRecs = [];

            card.recommendations.forEach((rec) => {
                optimizationOpportunity += rec.recommendation_value;

                if (isRecExcluded(rec, card, props.campaign.settings)) {
                    numExcludedRecs += 1;
                    excludedOpportunity += rec.recommendation_value;
                } else {
                    includedRecs.push(rec);
                }     
            });

            includedRecs.sort((a, b) => {
                return b.recommendation_value - a.recommendation_value;
            });

            remainingRecs += Math.min(props.campaign.settings.max_recs_per_card, includedRecs.length);
            for (var i = 0; i < Math.min(props.campaign.settings.max_recs_per_card, includedRecs.length); i++) {
                remainingOpportunity += includedRecs[i].recommendation_value;
            }

            groupedCards[card.id] = {
                numOptimizations: card.recommendations.length,
                optimizationOpportunity: optimizationOpportunity,
                numExcludedRecs: numExcludedRecs,
                excludedOpportunity: excludedOpportunity,
                finalIncludedCount: remainingRecs,
                remainingOpportunity: remainingOpportunity,
            }
        });

        return groupedCards;
    }, [drafts, props.campaign.settings]);

    const physicianStats = useMemo(() => {
        var optimizationOpportunity = 0;
        var numExcludedRecs = 0;
        var excludedOpportunity = 0;
        var remainingRecs = 0;
        var remainingOpportunity = 0;

        Object.values(cardStats).forEach((statGroup) => {
            optimizationOpportunity += statGroup.optimizationOpportunity;
            numExcludedRecs += statGroup.numExcludedRecs;
            excludedOpportunity += statGroup.excludedOpportunity;
            remainingRecs += statGroup.finalIncludedCount;
            remainingOpportunity += statGroup.remainingOpportunity;
        });

        return {
            numOptimizations: drafts.reduce((total, card) => total + cardStats[card.id].numOptimizations, 0),
            optimizationOpportunity: optimizationOpportunity,
            numExcludedRecs: numExcludedRecs,
            excludedOpportunity: excludedOpportunity,
            finalIncludedCount: remainingRecs,
            remainingOpportunity: remainingOpportunity,
        }
    }, [cardStats]);

    const sortedDrafts = useMemo(() => {
        return Object.values(drafts).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }, [drafts]);

    useEffect(() => {
        // Retrieve the expanded state from localStorage
        const expandedState = localStorage.getItem('expandedState');
        if (expandedState) {
            const parsedState = JSON.parse(expandedState);
            setOpen(parsedState[physician.id] || false);
        }
    }, [physician.id]);

    const handleCheck = (event) => {
        if (event.target.checked) {
            props.setCheckedPhysicians([...props.checkedPhysicians, physician.id]);
        } else {
            props.setCheckedPhysicians(props.checkedPhysicians.filter((id) => id !== physician.id));
        }
    }
    
    const handleRowClick = (event) => {
        if (event.target.type === 'checkbox') {
            return;
        }
        // Update the expanded state of the row
        setOpen(!open);

        // Store the expanded state in localStorage
        const expandedState = JSON.parse(localStorage.getItem('expandedState')) || {};
        localStorage.setItem('expandedState', JSON.stringify({
            ...expandedState,
            [physician.id]: !open,
        }));
    };

    function handleTextClick(event) {
        event.stopPropagation();
        navigate(`/physicians/${physician.id}/physicianTexting`);
    }

    return (
        <>
            <TableRow key={physician.id} onClick={(event) => handleRowClick(event)} hover sx={{ cursor: 'pointer' }}>
                <TableCell width='3%'>
                    { isCampaignPreview ? 
                        <Checkbox
                            checked={props.isChecked}
                            onChange={(event) => handleCheck(event)}
                        /> :
                        <IconButton size='small'>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    }
                </TableCell>
                <TableCell width='10%'>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <>
                            {physician.last_name + ', ' + physician.first_name}
                        </>
                        <IconButton size='small' onClick={(e) => handleTextClick(e)} disabled={physician.do_not_text}>
                            <TextsmsRounded />
                        </IconButton>
                    </Stack>
                </TableCell>
                <TableCell width='17%'>{reviewedDrafts.length} / {reviewableDrafts.length} Reviewed</TableCell>
                <TableCell width='10%'>{physicianStats.numOptimizations}</TableCell>
                <TableCell width='10%'>{physicianStats.numExcludedRecs}</TableCell>
                <TableCell width='10%'>{physicianStats.finalIncludedCount}</TableCell>
                <TableCell width='10%'>{caseCount}</TableCell>
                { props.campaign.status === 'open' &&
                    <>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={physicianStats.optimizationOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={physicianStats.excludedOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={physicianStats.remainingOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                    </>
                }
                { props.campaign.status === 'active' &&
                    <>
                        <TableCell width='20%'>
                            <NumericFormat
                                value={drafts.reduce((total, draft) => total + draft.cost_reduction_opportunity + draft.swap_opportunity + draft.compliance_opportunity, 0) / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                        <TableCell width='20%'>
                            <NumericFormat
                                value={drafts.reduce((total, draft) => total + draft.optimization_savings + draft.swap_savings + draft.compliance_savings, 0) / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                    </>
                }
                <TableCell width='10%'>
                    <EfficiencyBar efficiency={physician.efficiency} />
                </TableCell>
            </TableRow>
            {open && sortedDrafts.map((card) => (
                <DraftRow key={card.id} draft={card} cardStats={cardStats[card.id]} campaign={props.campaign}/>
            ))}
        </>
    )
}

function DraftRow(props) {
    const theme = useTheme();
    const draft = props.draft;
    const navigate = useNavigate();
    const isExcluded = props.draft.is_excluded ?? false;

    const goToCardView = () => {
        navigate(`/campaigns/${props.campaign.id}/previews/${draft.id}`);
    }

    return (
        <TableRow onClick={goToCardView} style={{ color: isExcluded ? `${theme.palette.red.main}` : '' }} hover sx={{ cursor: 'pointer' }}>
            <TableCell width='3%'/>
            <TableCell width='10%'/>
            <TableCell width='27%' style={{ color: 'inherit' }}>{draft.name}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{isExcluded ? 'Excluded' : props.cardStats.numOptimizations}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{isExcluded ? 'All' : props.cardStats.numExcludedRecs}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{isExcluded ? 0 : props.cardStats.finalIncludedCount}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{draft.usage_stats[0]?.usage_count ?? 1}</TableCell>
            
            { props.campaign.status === 'open' &&
                <>
                    <TableCell width='10%' style={{ color: 'inherit' }}>
                        { isExcluded ? 'Excluded' : 
                            <NumericFormat
                                value={props.cardStats.optimizationOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        }
                    </TableCell>
                    <TableCell width='10%' style={{ color: 'inherit' }}>
                        { isExcluded ? 'Excluded' :
                            <NumericFormat
                                value={props.cardStats.excludedOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        }
                    </TableCell>
                    <TableCell width='10%' style={{ color: 'inherit' }}>
                        { isExcluded ? 0 :
                            <NumericFormat
                                value={props.cardStats.remainingOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        }
                    </TableCell>
                </>
            }
            { props.campaign.status === 'active' &&
                <>
                    <TableCell width='10%'>
                        <NumericFormat
                            value={(draft.cost_reduction_opportunity + draft.swap_opportunity + draft.compliance_opportunity) / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={0}
                            fixedDecimalScale={true}
                        />
                    </TableCell>
                    <TableCell width='10%'>
                        <NumericFormat
                            value={(draft.optimization_savings + draft.swap_savings + draft.compliance_savings) / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={0}
                            fixedDecimalScale={true}
                        />
                    </TableCell>
                </>
            }
            <TableCell width='10%'>
                <EfficiencyBar efficiency={draft.efficiency ?? 0} isExcluded={isExcluded} />
            </TableCell>
        </TableRow>
    )
}

function EfficiencyBar(props) {
    return (
        <LinearProgress 
            variant='determinate' 
            value={props.efficiency * 100} 
            style={{ 
                height: 8,
            }} 
        />
    )
}

export default DraftTable