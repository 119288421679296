import React, { useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { theme } from '../../../theme';
import PhysicianEntry from './PhysicianEntry';

function PhysicianGroups(props) {
    const [selectedPhysician, setSelectedPhysician] = useState(null);

    const sortedPhysicians = Object.keys(props.draftGroupings).sort((a, b) => props.draftGroupings[b].numOverdue - props.draftGroupings[a].numOverdue).sort((a, b) => {
        props.draftGroupings[a].physician.last_name.localeCompare(props.draftGroupings[b].physician.last_name);
    });

    return (
        <Stack width='100%' divider={<Divider />} sx={{
            border: '1px solid',
            borderRadius: '8px',
            borderColor: theme.palette.border.main,
            backgroundColor: 'white',
        }}>
            { Object.keys(props.draftGroupings).length === 0 &&
                <Typography p={2} variant='normal' sx={{ fontSize: '16px' }}>No cards to review at this time. Check back again later!</Typography>
            }
            { sortedPhysicians.map((physicianId) => {
                return <PhysicianEntry 
                    key={physicianId} 
                    physician={props.draftGroupings[physicianId].physician} 
                    drafts={props.draftGroupings[physicianId].drafts} 
                    status={props.status}
                    selectedPhysician={selectedPhysician}
                    setSelectedPhysician={setSelectedPhysician}
                />
            })}
        </Stack>
    )
}

export default PhysicianGroups;
