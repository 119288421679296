import { Add } from '@mui/icons-material';
import { Button, Grid, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography, styled } from '@mui/material';
import format from 'date-fns/format/index.js';
import parseISO from 'date-fns/parseISO/index.js';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCampaignsQuery } from '../../api/campaignSlice';
import Filter from '../CustomComponents/Filter';
import { UserContext } from '../Hooks/useUser';

function Campaigns() {
    const { user } = useContext(UserContext);

    const {
        data: campaigns = [], 
        isLoading: isLoadingCampaigns
    } = useGetCampaignsQuery(user.hospital.id);

    const campaignStatusOptions = [
        'Open',
        'Pending',
        'Active',
        'Processing',
        'Complete'
    ];

    const [selectedStatus, setSelectedStatus] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const visibleCampaigns = useMemo(() => {
        var filteredCampaigns = campaigns.slice();
        if (selectedStatus) {
            filteredCampaigns = filteredCampaigns.filter((campaign) => campaign.status === selectedStatus.toLowerCase());
        }
        return filteredCampaigns.sort((a, b) => {
            return b.start_date.localeCompare(a.start_date)
        });
    }, [campaigns, selectedStatus]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - visibleCampaigns.length) : 0;
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const goToCampaign = (campaignId) => {
        navigate(`/campaigns/${campaignId}`);
    }

    const handleNewCampaignClick = () => {
        navigate('/campaigns/create');
    }

    useState(() => {
        setPage(0);
    }, [user.hospital.id]);
    
    return (
        <Grid container px={3} pt={2} spacing={2} height='100%' overflow='auto'>
            <Grid xs={12} item>
                <Typography variant='heavy' sx={{ fontSize: '30px' }}>Campaigns</Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack direction='row' justifyContent='space-between' py={1}>
                    <Stack direction='row'>
                        <Paper elevation={2}>
                            <Filter 
                                options={campaignStatusOptions}
                                value={selectedStatus ?? ''}
                                setSelectedValue={setSelectedStatus}
                                label='Campaign Status'
                                placeholder='Filter by Status'
                            />
                        </Paper>
                    </Stack>
                    <Button 
                        variant='contained' 
                        startIcon={<Add />} 
                        style={{ alignSelf: 'center' }}
                        onClick={handleNewCampaignClick}
                    >
                        New Campaign
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{ pb: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Hospital</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Start Date</StyledTableCell>
                                <StyledTableCell>End Date</StyledTableCell>
                                <StyledTableCell>Test Campaign?</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoadingCampaigns ? 
                                <TableRow>
                                    <StyledTableCell><Skeleton variant='text'/></StyledTableCell>
                                    <StyledTableCell><Skeleton variant='text'/></StyledTableCell>
                                </TableRow>
                                : (rowsPerPage > 0
                                    ? visibleCampaigns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : visibleCampaigns
                                ).map(campaign => (
                                    <StyledTableRow key={campaign.id} onClick = {()=> goToCampaign(campaign.id)}>
                                        <StyledTableCell>{campaign.name ?? ''}</StyledTableCell>
                                        <StyledTableCell>{campaign.hospital.name}</StyledTableCell>
                                        <StyledTableCell>{campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}</StyledTableCell>
                                        <StyledTableCell>{format(parseISO(campaign.start_date), 'MM/dd/yy')}</StyledTableCell>
                                        <StyledTableCell>{campaign.end_date ? format(parseISO(campaign.end_date), 'MM/dd/yy') : ''}</StyledTableCell>
                                        <StyledTableCell>{campaign.is_test_campaign === true ? 'Yes' : 'No'}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            { isLoadingCampaigns ? 
                                <TableRow>
                                    
                                </TableRow>
                                :
                                <TableRow>
                                    <TablePagination 
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={visibleCampaigns.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            }
                        </TableFooter>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    );
}

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F7F9FC'
    },

    '&:nth-of-type(even)': {
        backgroundColor: '#FFFFFF'
    },

    '&:nth-of-type(odd):hover': {
        backgroundColor: '#ECEFF2'
    },

    '&:nth-of-type(even): hover':{
        backgroundColor: theme.palette.background.darkGray
    },
    
    '&:hover':{
        cursor: 'pointer'
    }
}));

const StyledTableCell = styled(TableCell) (() => ({
    borderBottom: 'none'
}));

export default Campaigns;
